/*
 * @ModuleName: User Api
 * @Author: yuetchn@163.com
 * @LastEditTime: 2022-12-23 14:50:45
 */
import { IRequestPageInfo, IResponsePageInfo } from "@/types";
import req from "@/utils/request";

export const User_API = "system/user";
export const Users_API = "system/users";
export const UserPermission_API = "system/userPermission";
export const UserPassword_API = "system/userPassword";

/**
 * 获取用户权限信息
 * @returns
 */
export const GetUserPermission = () => req.Get(UserPermission_API);

/**
 * 获取用户集合
 * @param params
 * @returns
 */
export const GetUsers = (params: IRequestPageInfo) => req.Get<IResponsePageInfo<any>>(Users_API, params);

/**
 * 获取用户信息
 * @param id
 * @returns
 */
export const GetUser = (id: number) => req.Get(User_API, { user_id: id });

/**
 * 新增用户
 * @param data
 * @returns
 */
export const PostUser = (data: any) => req.Post(User_API, data);

/**
 * 修改用户
 * @param data
 * @returns
 */
export const PutUser = (data: any) => req.Put(User_API, data);

/**
 * 删除用户
 * @param ids
 * @returns
 */
export const DeleteUser = (ids: number[]) => req.Delete(User_API, { ids });

/**
 * 重置密码
 * @param user_id
 * @param password
 * @returns
 */
export const PutUserPassword = (user_id: number, password: string) => req.Put(UserPassword_API, { user_id, password });

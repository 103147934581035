/*
 * @ModuleName: Main
 * @Author: yuetchn@163.com
 * @LastEditTime: 2023-02-21 12:59:31
 */
import { createApp } from "vue";
import "ant-design-vue/dist/antd.css";
import pinia from "@/store"
import App from "@/App.vue";
import "@/utils/permission";
import router from "@/router";
import RegisterDirective from "@/directive";
import RegisterComponent from "@/components";
import RegisterGlobal from "@/global";
import "virtual:svg-icons-register";
import VueI18n from "@/locale";

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(VueI18n);
RegisterDirective(app);
RegisterComponent(app);
RegisterGlobal(app);

app.mount("#app");

/*
 * @ModuleName: MarkDown Options
 * @Author: yuetchn@163.com
 * @LastEditTime: 2022-07-25 11:10:39
 */
export const markDownProps = {
  modelValue: {
    type: String,
    default: "",
  },
}
<!--
 * @ModuleName: 请求加载遮罩层
 * @Author: yuetchn@163.com
 * @LastEditTime: 2022-05-05 12:52:13
-->
<template>
  <teleport to="body">
    <div v-if="showLoadingShade" class="g_loading_shade"></div>
  </teleport>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useAppStore } from "@/store/app";

export default defineComponent({
  setup() {
    const appStore = useAppStore();
    const showLoadingShade = computed(() => appStore.loadingShade);

    return {
      // ref
      showLoadingShade,
    };
  },
});
</script>
import { defineStore } from "pinia";
import { reactive, computed, toRefs } from "vue";
import * as cache from "@/utils/cache";

interface AppStates {
  /** 遮罩状态 */
  loadingShade: boolean;
  /** 侧边收缩状态 */
  isShrink: boolean;
  /** 当前路由状态 */
  nowRoutePath: string;
  /** 当前路由展开状态 */
  nowRouteSpread: string;
  /** client 宽度 */
  clientWidth: number;
  /** 国际化语言 */
  locale: string;
  /** 组件大小 "large" | "default" | "small" */
  size: string;
}

export const useAppStore = defineStore("appStore", () => {
  const state = reactive<AppStates>({
    loadingShade: false,
    nowRoutePath: cache.GetSessionStorageByString("nowRoutePath") || "/",
    isShrink: cache.GetSessionStorageByString("isShrink") === "1",
    nowRouteSpread: cache.GetSessionStorageByString("nowRouteSpread") || "",
    clientWidth: 0,
    locale: cache.GetLocalStorageByString("locale") || "zh-CN",
    size: (cache.GetLocalStorageByString("size") as any) || "default",
  })

  /**
   * 修改遮罩状态
   * @param loadingShade
   * @constructor
   */
  const set_loadingShade = (loadingShade: boolean) => {
    state.loadingShade = loadingShade;
  }

  /**
   * 侧边导航栏收缩
   * @param isShrink
   * @constructor
   */
  const set_shrink = (isShrink: boolean) => {
    state.isShrink = isShrink;
    cache.SetSessionStorageByString("isShrink", isShrink ? "1" : "0");
  }

  /**
   * 修改当前路由状态
   * @param nowRoutePath
   */
  const setNowRoutePath = (nowRoutePath: string) => {
    state.nowRoutePath = nowRoutePath;
    cache.SetSessionStorageByString("nowRoutePath", nowRoutePath);
  }

  /**
   * 修改当前路由展开状态
   * @param nowRouteSpread
   */
  const setNowRouteSpread = (nowRouteSpread: string[]) => {
    state.nowRouteSpread = nowRouteSpread.toString();
    cache.SetSessionStorageByString("nowRouteSpread", nowRouteSpread.toString());
  }

  /**
   * 重置路由状态
   */
  const resetRouteState = () => {
    state.nowRoutePath = "/";
    state.nowRouteSpread = "";
    cache.RemoveSessionStorage("nowRoutePath");
    cache.RemoveSessionStorage("nowRouteSpread");
  }

  const setClientWidth = (clientWidth: number) => {
    state.clientWidth = clientWidth;
  }

  const setLocale = (locale: string) => {
    state.locale = locale;
    cache.SetLocalStorageByString("locale", locale);
  }

  const setSize = (size: string) => {
    state.size = size;
    cache.SetLocalStorageByString("size", size);
  }

  const getNowRoutePath = computed(() => [state.nowRoutePath]);
  const getNowRouteSpread = computed(() => (state.nowRouteSpread !== "" ? state.nowRouteSpread.split(",") : []));

  return {
    ...toRefs(state),
    set_loadingShade,
    set_shrink,
    setNowRoutePath,
    setNowRouteSpread,
    resetRouteState,
    setClientWidth,
    setLocale,
    setSize,
    getNowRoutePath,
    getNowRouteSpread,
  }
})
/*
 * @ModuleName: IconSelect Options
 * @Author: yuetchn@163.com
 * @LastEditTime: 2022-07-25 13:53:56
 */
export const iconSelectProps = {

}

export const iconSelectEmits = {
  change: (name: string) => !!name,
}
/*
 * @ModuleName: EChartsComponent Setup
 * @Author: yuetchn@163.com
 * @LastEditTime: 2022-01-25 11:04:17
 */

import { watch } from "vue";
import { ECharts } from "echarts";
import { useAppStore } from "@/store/app";

export default (charts: ECharts) => {
  const appStore = useAppStore();
  watch(
    () => appStore.clientWidth,
    () => charts.resize(),
  );
  watch(
    () => appStore.isShrink,
    () => {
      setTimeout(() => {
        charts.resize();
      }, 500);
    },
  );
};

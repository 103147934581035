/*
 * @ModuleName: oAuth Api
 * @Author: yuetchn@163.com
 * @LastEditTime: 2023-01-13 09:18:29
 */
import req from "@/utils/request";

/**
 * 授权登录
 * @param data
 * @returns
 */
export const Login = (data: { account: string; password: string }) => req.Post(Login_API, data);
export const Login_API = "oAuth/login";

/**
 * 刷新Token
 * @returns 
 */
export const RefreshToken = () => req.Get<{ token: string, refresh_token: string }>(RefreshToken_API, null, { loading: false });
export const RefreshToken_API = "oAuth/refreshToken";
